@tailwind base;
html {
  scroll-behavior: smooth;
}

@tailwind components;
@tailwind utilities;

@import "react-slideshow-image/dist/styles.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  @apply text-4xl md:text-5xl font-bold text-white mb-4;
}

h2 {
  @apply text-[#003A90] my-[20px] text-[32px] font-bold;
}

h3 {
  @apply text-[#003A90] my-[20px] text-[32px] font-bold;
}

li {
  @apply list-none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  opacity: 0.1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  opacity: 0.1;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
