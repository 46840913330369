.dashboard {
  @apply hidden lg:flex flex-row items-center justify-center text-[#333333] transition-[top] ease-in-out duration-700 z-0 pr-[50px];
}

.customli {
  @apply relative whitespace-nowrap px-4 py-2;
}

.containersubmenu {
  @apply bg-white absolute rounded overflow-hidden top-[3.8rem] lg:shadow-lg;
}

.bar_menu {
  @apply cursor-pointer text-xl text-[#0072BC] hidden mx-2;
}

.bg_responsive {
  @apply flex justify-between items-center w-full text-[#0072BC] z-10 px-[30px] md:px-[50px] h-full;
}

.toggle {
  @apply flex justify-start items-center w-full;
}

@media only screen and (max-width: 1160px) {
  .bar_menu {
    @apply block;
  }

  .dashboard {
    @apply flex flex-col items-center justify-center absolute bg-white w-full top-[-35rem] left-0 font-medium shadow-md pr-[0px];
  }

  .active {
    @apply top-[3.75rem];
  }

  .customli {
    @apply whitespace-nowrap px-4 py-4;
  }

  .containersubmenu {
    @apply relative bg-white rounded overflow-hidden top-0 mx-4;
  }

  .toggle {
    @apply flex justify-start items-center w-full px-4 pb-4;
  }
}
